import Vue from 'vue'
import VueRouter from 'vue-router'
import User from './user/index'
import Restaurant from './restaurant/index'
import Address from './address/index'
import Order from './orders/index'
import Base from '../views/Base.vue'
import store from '../store';
Vue.use(VueRouter)

const routes = [
  ...User,
  ...Restaurant,
  ...Address,
  ...Order
]

const router = new VueRouter({
  mode: 'history',
  // history: true
  // base: 'front/', // only dev - sub folder ,
  routes: [{
    base: '',
    component: Base,
    path: '/',
    children: routes
  }, {
    path: '/',
    redirect: '/',
  }],
  scrollBehavior(to, from, savedPosition) { return { x: 0, y: 0 } }

});

router.beforeEach(async (to, from, next) => {
  var noauth = ['sign-in', 'sign-up', 'home', 'newhome', 'vendor', 'promo-code', 'banner-items', 'search', 'faq', 'privacypolicy', 'terms', 'support', 'forgot-password', 'category', 'details', 'qr', 'notfound', 'feedback', 'trackorder', 'help', 'pages', 'brand-items'];
  if (!noauth.includes(to.name)) { //InAuthRoute
    if (!Vue.prototype.$auth || !Vue.prototype.$auth.user) {
      // Vue.prototype.$auth.setUser(async (type) => {
      //   if (noauth.includes(type)) {
      //     if (type == 'sign-in') { //Token not found
      //       await store.restored;
      //       next({ name: type });
      //     } else { //User set now
      //       await store.restored;
      //       next();
      //     }
      //   } else {
      //     await store.restored;
      //     next({ name: type });
      //   }
      // });
      next();
    } else {      
      await store.restored;
      next();
    }
  } else { //NoAuthRoute        
    await store.restored;
    next();
  }
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router