const baseUrl = window.location.protocol + "//" + window.location.hostname + window.location.pathname;
const image_baseUrl = 'https://www.gerechtaanhuis.nl/admin/public/';
let host = window.location.hostname;
let loadSharAPI = "";
let apiUrl = 'https://www.gerechtaanhuis.nl/appapi/api/v1/';
let socketUrl = 'http://www.gerechtaanhuis.nl:8000';

if(host == 'localhost') {
    apiUrl = 'https://www.gerechtaanhuis.nl/appapi/api/v1/';
    socketUrl = 'http://www.gerechtaanhuis.nl:8000';
    loadSharAPI = '';    
}

export default {
    appurl: baseUrl,    
    coupon: image_baseUrl + 'media/Coupon/',
    applogo: image_baseUrl + 'media/foodapp/original/',
    signInLogo: image_baseUrl + 'media/LoginSigupImage/',
    google_key: window.google_map_key,
    api_url: apiUrl,
    socket_url: socketUrl,
    loadSharAPI:loadSharAPI
}