export default {
    gerUserWalletHistory: (data) => window.axios.post('get_user_wallet_history', data),
    razorpayRechargeWalletApi: (data) => window.axios.post('razorpay-recharge-wallet', data),
    paypalRechargeWalletApi: (data) => window.axios.post('paypal-recharge-wallet', data),
    stripeRechargewalletApi: (data) => window.axios.post('stripe-rechargewallet', data),
    yocoRechargewalletApi: (data) => window.axios.post('yoco-recharge-wallet', data),
    sslcommerzRechargewalletApi: (data) => window.axios.post('sslcommerz-recharge-wallet', data),
    peachRechargewalletApi: (data) => window.axios.post('peach-recharge-wallet', data),
    payuRechargeWalletApi: (data) => window.axios.post('payu-recharge-wallet', data),
    // pesapalRechargeWalletApi: (data) => window.axios.post('payu-recharge-wallet', data),
    paystackRechargeWalletApi: (data) => window.axios.post('ppaystack-recharge-wallet', data),
    paytmRechargeWalletApi: (data) => window.axios.post('paytm-recharge-wallet', data),
    getUserWalletAmount: (data) => window.axios.get('get_user_wallet_amount', data),
    getCashfreeRechargeWallet: (data) => window.axios.post('cashfree-recharge-wallet', data),
    getFlowRechargeWallet: (data) => window.axios.post('flow-recharge-wallet', data),
    geeksRechargeWallet: (data) => window.axios.post('geeks-recharge-wallet', data),
}