import Vue from 'vue'
import {Store} from 'vuex'
import VuexPersist from 'vuex-persist';
// import createPersistedState from 'vuex-persistedstate'

import { user } from "./user.module";
import { address } from "./address.module";
import { faq } from "./faq.module";
import { order } from './order.module';
import { promocode } from './promocode.module';
import { restaurant } from './restaurant.module';
import { product } from './product.module';
import { lang } from './lang.module';
import localForage from 'localforage';

//Vue.use(Vuex)
// const vuexLocalStorage = new VuexPersist({
//     key: 'vuex', // The key to store the state on in the storage provider.
//     // storage: window.localStorage, // or window.sessionStorage or localForage
//     // Function that passes the state and returns the state with only the objects you want to store.
//     reducer: state => {return state;},
//     // Function that passes a mutation and lets you decide if it should update the state in localStorage.
//     // filter: mutation => (true)
// })
const vuexLocal = new VuexPersist({
    storage: localForage,
    asyncStorage: false,
  });
export default new Store({
    // plugins: [vuexLocalStorage.plugin],    
    plugins: [vuexLocal.plugin],
    modules: {
        lang,
        user,
        address,
        faq,
        order,
        promocode,
        restaurant,
        product,
    }
})